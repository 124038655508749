
<template>
  <div class="uploadFils">
    <el-upload
      class="upload-demo"
      :action="action"
      :headers="header"
      ref="uploaddemo"
      :limit="limit" 
      :multiple="true"
      :on-success="AttachOnSuccess"
      :before-upload="beforeAvatarUpload"
      :show-file-list="showlist"
    >
      <!-- :multiple="multiple" -->
      <el-button
        size="small"
        v-if="IsDisabled"
        type="default"
        style="margin: 5px"
        @click="clickButton()"
        >点击上传</el-button
      >
    </el-upload>
    <el-table
      :data="newList"
      border 
      stripe
      :show-header="false"
      style="width: 100%"
      v-if="newList.length > 0"
    >
      <el-table-column prop="FileName" label="文件名称">
        <template slot-scope="scope">
          <el-button size="small" type="text">
            <a @click="linkDownload(scope.row.URL)">{{ scope.row.FileName }}</a>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="80px">
        <template slot-scope="scope">
          <el-button size="small" type="text" v-if="IsDownLoad">
            <a target="_blank" :href="scope.row.URL">下载</a>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="80px">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="text"
            v-if="IsDel"
            @click="deleteButton(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    //文件列表
    files: {
      type: Array,
      default: () => [],
    },
    //是否展示删除列(默认显示删除列,可自定义)
    isShowDel: {
      type: Boolean,
      default: true,
    },

    multiple: {
      type: Boolean,
      default: true,
    },
    IsDisabled: {
      type: Boolean,
      default: true,
    },
    IsDel: {
      type: Boolean,
      default: true,
    },
    IsDownLoad: {
      type: Boolean,
      default: true,
    },
    action: String,
    limit: Number,
    menuKey: String,
  },
  data() {
    return {
      showlist: true,
      newList: [],
      header:{Authorization:'Bearer '+window.localStorage.getItem("wtaccess_token")}
    };
  },

  methods: {
    clickButton() {
      this.$refs.uploaddemo.clearFiles();
      this.showlist = true;
    },
    //上传前判断
    beforeAvatarUpload(file) {
      let arr = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf','doc','xls','docx','xlsx','ppt','pptx','txt','zip','rar','dwj']
      var extend = file.name.substring(file.name.lastIndexOf('.') + 1)
      if(arr.indexOf(extend.toLowerCase())===-1)
      {
        this.$message.error(extend+"文件格式暂不支持上传!");
        return false;
      }
      //文件数量判断
      if (this.newList != null) {
        // const isLt5 = this.newList.length < this.limit;
        const isLt5 = this.newList.length < 15;//15个文件
        if (!isLt5) {
          this.$message.error("上传文件数量不能超过" + this.limit + " 个!");
        }
        const isLt2M = file.size / 1024 / 1024 < 30;//30兆大小
        if (!isLt2M) {
          this.$message.error('文件大小不能超过 1MB!');
        }
        return isLt5 && isLt2M;
      }
      this.showlist = true;
    },
    //根据索引删除文件列表中的文件
    deleteButton(index) {
      this.newList.splice(index, 1);
      this.$emit("delfun", { backData: this.newList });
    },
    AttachOnSuccess(response, file, fileList) {
      // console.log(response);
      this.showlist = false;
      let fileArray = {
        FileName: response.data.fileName,
        URL: response.data.httpHost + response.data.filePath,
      };
      if (this.newList == null) {
        this.newList = new Array();
        this.newList.push(fileArray);
      } else {
        this.newList.push(fileArray);
      }
    },
    Attachexceed(files, fileList) {
      this.$message({
        message: "当前上传最大数量不超过1个。",
        type: "warning",
      });
    },
    linkDownload(url) {
      window.open(url, "_blank"); // 新窗口打开外链接
    },
  },
  watch: {
    //监听文件列表
    newList: function (newVal, oldVal) {
      // console.log(1);
      this.$emit("fun", { backData: this.newList });
    },
  },
  computed: {},
  created() {
    //父组件传递过来的附件数组

    if (this.files != null && this.files.length > 0) {
      this.newList = this.files;
    } else {
      this.newList = [];
    }
  },
};
</script>

<style scoped>
.uploadFils {
  width: 100%;
  /* margin-top: 20px; */
  /* padding: 4px; */
  /* min-height: 100px; */
  box-sizing: border-box;
}
.mt20 {
  margin-top: 20px;
}
</style>

