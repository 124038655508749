<template>
  <section class="new_cssa">
    <!--工具条-->
    <div style="display:flex;" >
        <el-select v-model="sortvalue" style="width:237px" placeholder="请选择">
          <el-option
            v-for="item in sortoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="yearvalue" style="width:179px" placeholder="请选择">
          <el-option
            v-for="item in yearoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select :disabled="fenleiDisabled" v-model="fenlei" style="width:210px" placeholder="请选择">
          <el-option
            v-for="item in fenleioptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled">
          </el-option>
        </el-select>
        <el-input v-model="zerenren" style="width:165px" placeholder="输入责任人"></el-input>
        <!-- <el-input v-model="fenlei" style="width:150px" placeholder="输入分类"></el-input> -->
        <!--工具条-->
        <toolbar :buttonList="buttonList"
                @callFunction="callFunction"
                :buttonListmsg="buttonListmsg"></toolbar>
    </div>
    <!-- <el-col :span="5">
                 <el-upload    
                    class="upload-demo"    
                    action=""
                    :on-change="handleChange"
                    :file-list="fileListUpload"
                    :limit="1" 
                    :on-exceed="yearplanexceed"       
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"    
                    :auto-upload="false">
                    <el-row>
                         <el-button size="small" type="primary" icon="el-icon-upload" round>导入年度计划</el-button>
                          <span>限制文件类型*.xlsx,*.xls文件</span>
                    </el-row>    
                </el-upload>
          </el-col> -->
    <!-- <el-col :span="2">
              <el-button size="small" type="primary" round @click="downLoadExlce">模板下载</el-button>
          </el-col> -->
    <!--列表内容-->
    <div class="calc-heighta">
      <el-table :data="users"
                ref="tabYearplan"
                row-key="Id"
                lazy
                :load="load"
                :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
                :fit="true"
                :indent="30"
                highlight-current-row
                :cell-style="mycellStyle"
                @row-dblclick="checkInfo"
                @current-change="selectCurrentRow"
                :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                @cell-mouse-enter="tabmouseEnter"
                @cell-mouse-leave="tabmouseLeave"
                style="width: 100%">
        <el-table-column type="index" width="5">
          <template scope="scope">
          </template>
        </el-table-column>
        <el-table-column label="工作项目"
                         prop="WorkItem"
                         min-width="450px"
                         >
          <template slot-scope="scope">
            <span :style="{'cursor':'default','display':'block','padding-left':'20px','margin-top':scope.row.IsHasChildren ?'-22px':'0px','margin-bottom':scope.row.IsHasChildren ?'0px':'18px','margin-left': scope.row.MarginLeft+'px' }">{{scope.row.Number}}、{{scope.row.WorkItem}}
              
            </span>
          </template>
        </el-table-column>
        <el-table-column label="计划完成时间" min-width="120px" >
          <template slot-scope="scope">
              <div style="margin-left:0px">
                <!-- <div v-if="scope.row.Tips" >分类:{{scope.row.Tips}}</div> -->
                <!-- <div>进度:{{formatProgress(scope.row)}}</div> -->
                <div>{{formatPlanComplateTime(scope.row)}}</div>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="责任人"
                         min-width="95px"
                         prop="PersonOfDuty" align="center">
          <template slot-scope="scope">
            <div>{{scope.row.PersonOfDuty}}</div>
            <el-tag  v-if="scope.row.Tips" >{{scope.row.Tips}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="任务状态"
                         prop="Status"
                         align="center"
                         min-width="100px">
          <template slot-scope="scope">
              <div v-if="scope.row.TaskProgressValue<100"  style="font-size:10px;">进度:{{formatProgress(scope.row)}}</div>
              <div>
                <el-tag v-if="scope.row.Status == 0"
                        effect="dark"> 进行中</el-tag>
                <el-tag v-if="scope.row.Status == 1"
                        effect="dark"
                        type="warning">已完成审核中</el-tag>
                <el-tag v-if="scope.row.Status == 2"
                        effect="dark"
                        type="success">已完成</el-tag>
                <el-tag v-if="scope.row.Status == -1"
                        effect="dark"
                        type="info">已取消</el-tag>
                <el-tag v-if="scope.row.Status == 3"
                        effect="dark"
                        type="danger">退回</el-tag>
                <el-tag v-if="scope.row.Status == 4"
                        effect="dark"
                        type="warning">新建任务待审核</el-tag>
                <el-tag v-if="scope.row.Status == 5"
                        effect="dark"
                        type="danger">取消任务待审核</el-tag>
                <el-tag v-if="scope.row.Status == 6"
                        effect="dark"
                        type="danger">新建任务退回</el-tag>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="工作类型"
                         prop="WorkType"
                         :formatter="formatCommonTaskClassifyText"
                         min-width="80px"></el-table-column>
        <!-- <el-table-column label="所属年份"
                         prop="OfYear"
                         min-width="80px"
                         :formatter="formatOfYear"></el-table-column> -->
        <el-table-column label="周期"
                         prop="CreateTime"
                         min-width="110"
                         :formatter="formatCreateTime">
          <template slot-scope="scope">
            <el-tooltip placement="left" effect="light">
              <div slot="content">
                {{ formatTipCreateTime( scope.row) }}
              </div>
              <div style="font-size:9px;cursor:default">
                {{ formatCreateTime( scope.row) }}创建
              </div>
            </el-tooltip>
            <el-tooltip placement="left" effect="light">
                <div slot="content">
                    {{ formatTipLastInputProcessTime( scope.row) }}
                </div>
                <div style="font-size:9px;cursor:default">
                    {{ formatLastInputProcessTime( scope.row) }}
                </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right"
                         min-width="130px">
          <template slot-scope="scope">
            <el-row>
                <el-button type="text" v-if="localuser == scope.row.PersonOfDuty"
                           size="medium"
                           @click="checkInfo(scope.row)">维护</el-button>
                <el-button type="text" v-else
                           size="medium"
                           @click="checkInfo(scope.row)">查看</el-button>
                <el-button @click="handleCollect(scope.row)" style="color:#FF9800;margin-left:3px;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null" :title="scope.row.CollectId==null?'特别关注':'已特别关注'" type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination align='center'
                     @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-size="pageSize"
                     layout="prev, pager, next, jumper"
                     :page-count="total"
                     :total="totaldata">
      </el-pagination>
    </div>
    <!--编辑任务界面-->
    <el-dialog
        :visible.sync="editFormVisible" 
        v-model="editFormVisible" 
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center>
        <template slot="title">
            <span style="color:#FFFFFF;font-size: 16px;">编辑年度计划</span>
        </template>
        <el-form :model="editForm" label-width="85px" :rules="editFormRules" ref="editForm" style="white-space:nowrap;">
            <el-form-item label="工作项目:" prop="WorkItem">
                <el-input v-model="editForm.WorkItem" maxlength="30" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="工作描述:" prop="WorkDescription">
                <el-input v-model="editForm.WorkDescription" type="textarea" maxlength="500" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="考核依据:" prop="ExamBasis">
                <el-input maxlength="100"  show-word-limit  v-model="editForm.ExamBasis" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="核算方式:" prop="ScoreCalcMode">
                <el-input maxlength="500"  show-word-limit v-model="editForm.ScoreCalcMode" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="权重:" prop="Rate">
                <el-input v-model="editForm.Rate" type="number" min= 1 max= 100 placeholder="请填写1~100范围内的数字"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="计划完成时间:" prop="PlanComplateTime">
                    <el-date-picker  :picker-options="pickerOptions0"  v-model ="editForm.PlanComplateTime" type = "date" value-format="yyyy-MM-dd" placeholder = "请选择日期" style="left: 7%;"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="所属年份:" prop="OfYear">
                        <el-date-picker :readonly="true" v-model ="editForm.OfYear" type = "year" value-format="yyyy-MM-dd" placeholder = "请选择年份" :picker-options="pickerOptions0"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <el-row style="height: 40px; margin-bottom: 20px;">
                <el-col :span="0.1">
                    <span style="color: red;">*</span>
                </el-col>
                <el-col :span="2.5">
                    <span>计划完成时间:</span>
                </el-col>
                <el-col :span="7">
                    <el-date-picker :picker-options="pickerOptions0" v-model ="editForm.PlanComplateTime2" type = "date" value-format="yyyy-MM-dd" placeholder = "请选择日期"></el-date-picker>
            </el-col>
            </el-row> -->
                <el-form-item label="备注:" prop="Remark">
                <el-input v-model="editForm.Remark" maxlength="50" show-word-limit></el-input>
                </el-form-item>
        </el-form>
            <div slot="footer" class="dialog-footer">
            <el-button type="goon" @click.native="editSubmit">提交</el-button>
            </div>
    </el-dialog>
    <!--查看界面-->
    <el-dialog :visible.sync="checkVisible"
               v-model="checkVisible"
               @closed="checkclose"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               style="width: 145%; left: -300px; top:-80px"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">查看年度计划</span>
      </template>
      <div class="yearplandiv">
        <el-col :span="12">
          <el-form :model="checkForm"
                   label-width="85px"
                   ref="checkForm">
            <el-form-item label="工作类型:"
                          prop="WorkItemType">
              <el-input v-model="WorkItemType"
                        :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="工作项目:"
                          prop="WorkItem">
              <el-input v-model="checkForm.WorkItem"
                        :readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="工作描述:"
                          prop="WorkDescription">
              <el-input v-model="checkForm.WorkDescription"
                        :readonly="true"
                        type="textarea"
                        :autosize="{minRows:1,maxRows:4}"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="责任人:"
                              prop="PersonOfDuty">
                  <el-input v-model="checkForm.PersonOfDuty"
                            :readonly="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="完成时间:"
                              prop="PlanComplateTime">

                  <el-input :value="handleFormatTime(checkForm.PlanComplateTime)"
                            :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="考核依据:"
                          prop="ExamBasis">
              <el-input v-model="checkForm.ExamBasis"
                        :readonly="true"
                        type="textarea"
                        :autosize="{minRows:1,maxRows:4}"></el-input>
            </el-form-item>
            <el-form-item label="核算方式:"
                          prop="ScoreCalcMode">
              <el-input v-model="checkForm.ScoreCalcMode"
                        :readonly="true"
                        type="textarea"
                        :autosize="{minRows:1,maxRows:4}"></el-input>
            </el-form-item>

            <el-row>
              <el-col :span="12">
                <el-form-item label="权重:"
                              prop="Rate">
                  <el-input v-model="checkForm.Rate"
                            type="number"
                            :readonly="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="备注:"
                              prop="Remark">
                  <el-input v-model="checkForm.Remark"
                            :readonly="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-form-item label="是否审核:" prop="IsNeedApproval">
                        <el-switch v-model="checkForm.IsNeedApproval" disabled></el-switch>
                    </el-form-item> -->
            <el-form-item label="任务进度:">
              <el-progress :percentage="pValue1"
                           :stroke-width="12"
                           style="margin-top: 10px;"></el-progress>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-card class="yearplancard" style="margin-left:10px">
            <div slot="header">
              <span style="font-size:20px">工作进度维护</span>
              <el-row v-show="isyearplanpro">
                <el-button type="text"
                           @click="addpro">新增</el-button>
                <!-- <el-button  type="text" @click="editpro">编辑</el-button> -->
                <el-button type="text"
                           @click="deletepro">删除</el-button>
              </el-row>
            </div>
            <div class="yeardiv">
              <el-empty v-if="yearplans.length==0" description="工作有新进度记得更新哦~"></el-empty>
              <el-timeline :reverse="false">
                <el-timeline-item v-for="(yearplan, index) in yearplans"
                                  :key="yearplan.Id"
                                  color="#2d8cf0"
                                  :timestamp="yearplan.ProgressValue+'% '+yearplan.CreatedOn"
                                  placement="top">
                  <el-card class="box-card"
                           @click.native="handleYearplanProcessSelectChange(yearplan.Id, index)"
                           >
                    <div style="white-space: pre-line"
                         class="text item role-item">
                      {{ yearplan.ProgressDescription }}
                    </div>
                    <div>
                      <el-dropdown @command="showTaskFile"
                                   v-show="0<yearplan.Files.length">
                        <span class="el-dropdown-link">
                          <i class="el-icon-paperclip"></i> 附件({{yearplan.Files.length-1}})<i class=" el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item class="eldropdownitem" v-bind:key="f.FileName"
                                            v-for="f in yearplan.Files"
                                            :command='f.FileAddress'>{{f.FileName}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                    
                    <div style="margin-top:10px;" v-show="yearplan.ReviewCount>0">
                      <el-collapse  @change="handleChangeReview">
                        <el-collapse-item :name="yearplan.Id">
                          <template slot="title">
                            <span style="color:orange;"><i class="el-icon-chat-dot-square"></i> 评论({{yearplan.ReviewCount}})</span>
                          </template>
                          <!-- <div>{{mapReview.get(yearplan.Id)}}</div> -->
                          <div style="background-color:#f5f7fa;border-radius:10px; margin-bottom:10px;border-bottom:0px solid #f5f7fa" v-bind:key="a.Id" v-for="a in mapReview.get(yearplan.Id)">
                              <div style="color:#999" ><span style="margin-left:5px"><i class="el-icon-user-solid"></i>{{a.CreatedBy}}</span><span style="float:right;margin-right:5px">{{a.CreateTime}}</span></div>
                              <div style="margin-left:5px;margin-right:5px; background-color:#f5f7fa;border-radius:5px" v-html="a.ReviewContent" ></div>
                              <div style="margin-left:20px" v-if="getReplyReview(mapReview.get(yearplan.Id),a.ReplayID).CreatedBy!=''" >
                                <p style="color:red">[回复]</p>
                                <div style="margin-left:5px;margin-right:5px; background-color:#f5f7fa;border-radius:5px" v-html="getReplyReview(mapReview.get(yearplan.Id),a.ReplayID).ReviewContent" ></div>
                                <div>
                                  <span style="color:#999"><i class="el-icon-user-solid"></i>{{ getReplyReview(mapReview.get(yearplan.Id),a.ReplayID).CreatedBy }}</span>
                                  <span style="float:right;margin-right:5px;color:#999">{{getReplyReview(mapReview.get(yearplan.Id),a.ReplayID).CreateTime}}</span>
                                </div>
                              </div>
                              <div style="text-align:right;margin-right:5px">

                              <el-popover @show="handlePopoReplyShow"
                                placement="bottom"
                                height="300"
                                trigger="click"
                                >
                                
                                <editor style="width:300px; " :catchData="catchData" />
                                
                                <el-button style="float:right;margin-top:5px"  type="primary" size="mini" @click="handleReplyReview(yearplan,a.Id)">回复</el-button>
                                                
                                <el-button size="mini"  slot="reference"  type="text" icon="el-icon-chat-dot-round" >回复</el-button>
                                            
                              </el-popover>
                              </div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>                        
                    </div>
                    <div>
                      <div style="float:right" >

                        <el-popover @show="handlePopoShow"
                          placement="bottom"
                           height="300"
                          trigger="click"
                          >
                          <editor style="width:300px; " :catchData="catchData" />
                          
                          <div style="position:absolute;bottom:12px;color:#999;font-size:7px;"><i class="el-icon-user-solid"></i>{{myname()}}</div>
                          <el-button style="float:right;margin-top:5px" type="primary" size="mini" @click="handleSendReview(yearplan)">发表</el-button>
                                                
                          <el-button style="margin-top:5px;margin-bottom:10px" slot="reference" size="mini" round type="normal" icon="el-icon-chat-dot-round" >发表评论</el-button>
                        </el-popover>
                      </div>
                    </div>
                  </el-card>
                </el-timeline-item>
              </el-timeline>
              <!--列表-->
              <!-- <el-table :data="yearplans"
                        highlight-current-row
                        :row-class-name="taskProgressRowClassName"
                        @row-click="onRowClick"
                        @current-change="selectProRow"
                        :row-style="{height:'40px'}"
                        :cell-style="{padding:'0px'}">
                <el-table-column type="index"></el-table-column>
                <el-table-column label="进度内容"
                                 prop="ProgressDescription">
                  <template slot-scope="scope">
                    <span style="font-size:3px;">{{scope.row.ProgressDescription}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="进度"
                                 prop="ProgressValue"
                                 min-width="50%"></el-table-column>
                <el-table-column label="添加时间"
                                 min-width="85%"
                                 prop="CreatedOn"
                                 :formatter="formatCreatedOn"></el-table-column>
              </el-table> -->
            </div>
          </el-card>
        </el-col>
      </div>
    </el-dialog>
    <!--新增工作进度界面-->
    <el-dialog :visible.sync="addProgressVisible"
               v-model="addProgressVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               @opened="addyearplanopen"
               style="width: 80%; left: 15%;"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">新增工作进度</span>
      </template>
      <el-form :model="addProgressForm"
               :rules="addProgressFormRule">
        <el-form-item label="进度内容:"
                      prop="ProgressDescription">
          <el-input v-model="addProgressForm.ProgressDescription"
                    maxlength="700"
                    placeholder="请尽可能的描述任务推进过程、量化数据和成果"
                    show-word-limit
                    type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="具体进度:">

          <el-row>
            <el-col :span="11">
              <el-slider v-model="pValue2"
                         @input="handleProcessInput"
                         :step="1"></el-slider>
            </el-col>
            <el-col :span="2.2">
              <div style="margin-left:9px">{{pValue2}}%</div>
            </el-col>
            <el-col :span="7">
              <div style="margin-left:3px;font-size:12px">
                <i class="el-icon-info"></i>拖动滑块设置进度百分比
              </div>
            </el-col>
          </el-row>

          <!-- <el-row>
                            <el-col :span="15">
                                <el-progress style="margin-top:10px" :percentage="pValue2" :color="customColor"></el-progress>
                            </el-col>
                            <el-col :span="1.5">
                                <div>
                                    <el-button-group>
                                        <el-button icon="el-icon-plus" @click="increase" v-show="showplus"></el-button>
                                        <el-button icon="el-icon-minus" style="float:right;" v-show="showminus"  @click="decrease"></el-button>
                                    </el-button-group>
                                </div>
                            </el-col>
                        </el-row> -->
        </el-form-item>
        <el-form-item label="附件:">
          <upload-files :files="fileList"
                        :key="key"
                        action="https://api.gradgroup.cn/ftp/ftp/upload"
                        :limit="5"
                        @fun="dealFiles2"
                        @delfun="deleteFiles2"
                        :multiple="false"
                        :IsDisabled="operation1"
                        :IsDel="operation1"></upload-files>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click.native="checkSubmit">完成</el-button>
      </div>
    </el-dialog>
    
    <el-dialog :visible.sync="jixiaopingguVisible"
               v-model="jixiaopingguVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               width="1000px"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">【{{pingguWorkitem}}】绩效评估查看</span>
      </template>
      <el-form label-width="85px"
               style="white-space:nowrap;">
        <el-table :data="aboutTaskPingguList"
                  height="300px"
                  row-key="Id"
                  lazy
                  :indent="30"
                  :load="load"
                  :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
                  :row-class-name="MultitableRowClassName"
                  @row-dblclick="checkAboutInfo"
                  :row-style="{height:'40px'}"
                  :cell-style="{padding:'0px'}"
                  :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                  :fit="true">
          <el-table-column type="index"
                           min-width="50px"></el-table-column>
          <el-table-column label="评估月份"
                           prop="Month"
                           min-width="100px"
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="分数"
                           prop="Score"
                           min-width="130px"></el-table-column>
          <el-table-column label="评价内容"
                           prop="Description"
                           min-width="300px"></el-table-column>

        </el-table>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="goon"
                   @click="jixiaopingguVisible=false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="lookOneAboutTaskVisible"
               v-model="lookOneAboutTaskVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               style="width: 145%; left: -300px; top:-80px"
               center>
      <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">查看任务</span>
      </template>
      <div class="managercheckdiv">
        <el-col :span="12">
          <el-form :model="checkForm"
                   label-width="85px"
                   ref="checkForm"
                   style="white-space:nowrap;">
            <el-form-item label="任务类型:"
                          prop="CommonTaskClassifyText">
              <el-input v-model="CommonTaskClassifyText"
                        readonly></el-input>
            </el-form-item>
            <div v-show="showregularproject">
              <el-row>
                <el-col :span="17">
                  <el-form-item label="例会来源:"
                                prop="MeetingName">
                    <el-input v-model="checkForm.MeetingName"
                              disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="审核人:"
                                prop="ApprovalName">
                    <el-input v-model="checkForm.ApprovalName"
                              disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div v-show="showprofessionmeetong">
              <el-row>
                <el-col :span="17">
                  <el-form-item label="会议名称:"
                                prop="MeetingName">
                    <el-input v-model="checkForm.MeetingName"
                              disabled
                              type="textarea"
                              :autosize="{minRows:1,maxRows:2}"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item label="指令人:"
                                prop="InstructUserName">
                    <el-input v-model="checkForm.InstructUserName"
                              disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-form-item label="工作项目:"
                          prop="WorkItem">
              <el-input v-model="checkForm.WorkItem"
                        disabled></el-input>
            </el-form-item>
            <el-form-item label="工作描述:"
                          prop="WorkDescription">
              <el-input v-model="checkForm.WorkDescription"
                        type="textarea"
                        disabled
                        :autosize="{minRows:1,maxRows:4}"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="8"
                      v-show="createPerson">
                <el-form-item label="创建人:"
                              prop="CreatedBy">
                  <el-input v-model="checkForm.CreatedBy"
                            disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8"
                      v-show="showAssign">
                <el-form-item label="指派人:"
                              prop="AssignName">
                  <el-input v-model="checkForm.AssignName"
                            disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="责任人:"
                              prop="PersonOfDuty">
                  <el-input v-model="checkForm.PersonOfDuty"
                            disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="height: 40px; margin-bottom: 20px;">
              <el-col :span="12">
                <el-form-item label="计划完成时间:"
                              prop="PlanComplateTime"
                              label-width="35%">
                  <el-date-picker v-model="checkForm.PlanComplateTime"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  disabled
                                  style="width: 90%"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="创建时间:"
                              prop="CreateTime"
                              label-width="40%">
                  <el-date-picker v-model="checkForm.CreateTime"
                                  type="date"
                                  value-format="yyyy-MM-dd"
                                  disabled
                                  style="width: 90%"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-show="showyearplan">
              <el-form-item label="考核依据:"
                            prop="ExamBasis">
                <el-input v-model="checkForm.ExamBasis"
                          type="textarea"
                          disabled
                          :autosize="{minRows:1,maxRows:4}"></el-input>
              </el-form-item>
              <el-form-item label="核算方式:"
                            prop="ScoreCalcMode">
                <el-input v-model="checkForm.ScoreCalcMode"
                          type="textarea"
                          disabled
                          :autosize="{minRows:1,maxRows:4}"></el-input>
              </el-form-item>
              <el-form-item label="权重:"
                            prop="Rate">
                <el-input v-model="checkForm.Rate"
                          type="number"
                          disabled></el-input>
              </el-form-item>
            </div>
            <div v-show="showorganize">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="发起部门:"
                                prop="OutDepartment">
                    <el-input v-model="checkForm.OutDepartment"
                              disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="发起人员:"
                                prop="OutPeople">
                    <el-input v-model="checkForm.OutPeople"
                              disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="跨入部门:"
                                prop="AcceptDepartment">
                    <el-input v-model="checkForm.AcceptDepartment"
                              disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="协同人员:"
                                prop="PersonOfDuty">
                    <el-input v-model="checkForm.PersonOfDuty"
                              disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-form-item label="备注:"
                          prop="Remark">
              <el-input v-model="checkForm.Remark"
                        disabled></el-input>
            </el-form-item>
            <el-row style="height: 40px;">
              <el-col :span="2.5">
                <span>是否需审核:</span>
              </el-col>
              <el-col :span="2">
                <el-switch v-model="checkForm.IsNeedApproval"
                           disabled></el-switch>
              </el-col>
            </el-row>
            <el-form-item label="任务进度:">
              <el-progress :percentage="pValue1"
                           :stroke-width="12"
                           style="margin-top: 10px;"></el-progress>
            </el-form-item>
            <!-- <div v-show="showtoolbar" style="margin-left: 50%;">
                        <toolbar :buttonList="buttonList2" @callFunction="callFunction" :buttonListmsg="buttonListmsg2"></toolbar>
                        </div> -->
            <el-form-item label="附件:">
              <a target="_blank"
                 :href="checkForm.FileAddress">{{checkForm.FileName}}</a>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-card id="managercheckcard">
            <div slot="header">
              <span style="font-size:20px">工作进度维护</span>
              <!-- <el-row v-show="istaskpro">
                            <el-button  type="text">新增</el-button>
                            <el-button  type="text">删除</el-button>
                        </el-row>             -->
            </div>
            <div class="tasksdiv">
              <el-timeline :reverse="false">
                <el-timeline-item v-for="(task, index) in tasks"
                                  :key="task.Id"
                                  color="#2d8cf0"
                                  :timestamp="task.ProgressValue+'% '+task.CreatedOn"
                                  placement="top">
                  <el-card class="box-card"
                           @click.native="handleProcessSelectChange(task.Id, index)"
                           :class="task.Id == currentActiveId ? 'active' : ''">
                    <div style="white-space: pre-line"
                         class="text item role-item">
                      {{ task.ProgressDescription }}
                    </div>
                    <div>
                      <el-dropdown @command="showTaskFile"
                                   v-show="0<task.Files.length">
                        <span class="el-dropdown-link">
                          <i class="el-icon-paperclip"></i> 附件({{task.Files.length-1}})<i class=" el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item class="eldropdownitem"
                                            v-for="f in task.Files"
                                            :command='f.FileAddress'>{{f.FileName}}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </el-card>
                </el-timeline-item>
              </el-timeline>
              <!--列表-->
              <!-- <el-table :data="tasks"
                        highlight-current-row>
                <el-table-column type="index"></el-table-column>
                <el-table-column label="进度内容"
                                 prop="ProgressDescription">
                  <template slot-scope="scope">
                    <span style="font-size:3px;">{{scope.row.ProgressDescription}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="进度"
                                 prop="ProgressValue"
                                 min-width="50%"></el-table-column>
                <el-table-column label="添加时间"
                                 min-width="85%"
                                 prop="CreatedOn"
                                 :formatter="formatCreatedOn"></el-table-column>
                <el-table-column label="附件"
                                 min-width="45%"
                                 prop="FileAddress">
                  <template slot-scope="scope">
                    <ul>
                      <li v-for="(item,idx) in scope.row.Files"
                          :key="idx"
                          style="line-height: 20px;">
                        <a target="_blank"
                           :href="item.FileAddress">{{item.FileName}}</a>
                      </li>
                    </ul>
                  </template>
                </el-table-column>
              </el-table> -->
            </div>
          </el-card>
        </el-col>
      </div>
      <!-- <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click.native="checkSubmit">完成</el-button>
            </div> -->
    </el-dialog>

  </section>
</template>
<script>
import util from '../../../util/date';
import { Loading } from 'element-ui'
import { AddTaskCollect, RemoveTaskCollect, AddTaskProgressReview,QueryReviewByProgressId,QueryYearPlanAboutTaskByYearplanId, QueryTaskPingfenByTaskId, QueryPageYearPlanByUserCode, GetMyUpLeader, GetClassifyList, GetUserPostList, AddYearPlan, QueryTaskProgressByTaskId, AddTaskProgress, UpdateTaskProgress, DeleteTaskProgress, DeleteTaskById, AssignTask, CancelTask, QueryChildTasksByParentId, UpdateTaskContent, QueryYearPlanChildTasksByParentId, ReSubmitApproval, UpdateYearPlanTaskById, ImportYearPlan, ImportYearPlan20211213, DecomposesYearPlan } from '../../api/oa';
import { getButtonList } from "../../promissionRouter";
import UploadFiles from "../../components/UploadFiles";
import Userchoosetool from "../../components/Userchoosetool";
import Toolbar from "../../components/Toolbar";
import editor from "../../components/editor";
export default {
  components: { editor, Toolbar, UploadFiles, Userchoosetool },
  data () {
    return {
      fenleiDisabled:false,
      currentYearplanActiveId: null,
      currentActiveId: null,
      maps: new Map(),
      loading: '',
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      localuser: null,
      proRow: null,
      progresscheck: null,      //进行完成进度验证
      fileListUpload: [],     //上传文件数组
      fileTemp: null,
      fileList: [],
      operation1: true,
      key: 0,
      yearplanexcelList: [],    //定义导入excel数据数组
      submitList: [],           //最终导入数据
      cpoysubmitList: [],       //最终导入数据复制
      yearplans: [],
      users: [],
      text1: "",
      buttonList: [],
      WorkItem2: null,
      WorkDescription2: null,
      PlanComplateTime2: null,
      upleader: null,
      pValue1: null,   //进度条数值
      pValue2: null,
      customColor: '#6f7ad3',   //进度条颜色
      WorkItemType: null,
      dutyneedname: null,
      description: null,
      needName: null,
      needresloveName: null,
      assigncontrol: true,
      showminus: false,
      showplus: true,
      itemcontent: null,
      singleAss: false,     //是否单独指派
      dutypeo: 'dutypeo',
      assignpeo: 'assignpeo',
      reslovepeo: 'reslovepeo',
      buttonListmsg: 'taskmanage',
      Leave: '1',
      resloveLeave: '1',
      NeedInput: '1',
      isyearplanpro: true,
      showSubmit: true,    //提交按钮是否显示
      showNext: false,     //下一步按钮是否显示
      currentRow: null,
      currentProgressRowIndex: null,
      filters: {
        name: ''
      },
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      addFormVisible: false,
      yearplantaskid: '',
      lookOneAboutTaskVisible: false,
      lookAboutTaskVisible: false,
      jixiaopingguVisible: false,
      pingguWorkitem: '',
      fenlei:'',
      zerenren:'',
      tasks: [],
      istaskpro: false,
      showAssign: false,
      createPerson: false,                    //创建人是否显示
      showyearplan: false,                    //年度计划查看界面显示控制
      showorganize: false,                     //跨部门协同查看界面显示控制
      showregularproject: false,               //例会来源查看界面控制
      showprofessionmeetong: false,            //专项会议查看界面控制
      CommonTaskClassifyText: null,
      editFormVisible: false,
      addAssignFormVisible: false,  //新增并指派界面初始化
      checkVisible: false,          //查看界面初始化
      addProgressVisible: false,
      editProgressVisible: false,
      excelVisible: false,
      resloveVisible: false,        //分解年度计划
      addresloveVisible: false,
      CommonTaskClassifyList: [],
      mapReview:new Map(),
      addForm: {
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsAssign: false,
        IsNeedApproval: false,
        CreatedBy: null,
        CreatedId: null,
        AssignName: null,
        AssignCode: null,
        PersonOfDuty: null,
        PersonOfDutyCode: null
      },
      addFormRules: {
        WorkItem: [
          { required: true, message: '请填写项目名称', trigger: 'blur' }
        ],
        PlanComplateTime: [
          { required: true, message: '请选择计划完成时间', trigger: 'blur' }
        ],
        WorkDescription: [
          { required: true, message: '请填写具体工作描述', trigger: 'blur' }
        ],
        OfYear: [
          { required: true, message: '请选择所属年份', trigger: 'blur' }
        ],
        Rate: [
          { required: true, message: '请填写权重(数字)', trigger: 'blur' }
        ],
        ExamBasis: [
          { required: true, message: '请填写考核依据', trigger: 'blur' }
        ],
        ScoreCalcMode: [
          { required: true, message: '请填写核算方式', trigger: 'blur' }
        ]
      },
      addProgressFormRule: {
        ProgressDescription: [
          { required: true, message: '请输入进度描述', trigger: 'blur' }
        ]
      },
      editFormRules: {
        WorkItem: [
          { required: true, message: '请填写工作项目', trigger: 'blur' }
        ],
        WorkDescription: [
          { required: true, message: '请输入工作描述', trigger: 'blur' }
        ],
        ExamBasis: [
          { required: true, message: '请填写考核依据', trigger: 'blur' }
        ],
        ScoreCalcMode: [
          { required: true, message: '请填写核算方式', trigger: 'blur' }
        ],
        Rate: [
          { required: true, message: '请填写权重', trigger: 'blur' }
        ],
        PlanComplateTime: [
          { required: true, message: '请选择计划完成时间', trigger: 'blur' }
        ],
        OfYear: [
          { required: true, message: '请选择所属年份', trigger: 'blur' }
        ]
      },
      aboutTaskList: [],
      aboutTaskPingguList: [],
      editFormVisible: false,
      taskClassify: 0,
      ClassifyModules: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "日常工作"
        },
        // {
        //     value: 2,
        //     label: "跨部门协调"
        // },
        {
          value: 3,
          label: "例会项目"
        },
        // {
        //     value: 4,
        //     label: "年度计划"
        // },
        {
          value: 5,
          label: "专项会议"
        },
      ],
      editForm: {              //编辑表单初始化
        Id: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
      },
      checkForm: {            //查看表单初始化
        Id: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null
      },
      addProgressForm: {            //进度新增表单初始化
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null
      },
      editProgressForm: {          //进度编辑表单初始化
        Id: null,
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null
      },
      excalForm: {
        TaskData: {
          CreatedId: null,
          CreatedBy: null
        },
        YearPlanData: []
      },
      resloveForm: {
        TaskId: null,
        YearPlanId: null,
        DecomposesUserName: null,
        DecomposesUserCode: null,
        WorkItem: null,
        WorkDescription: null,
        ExamBasis: null,
        ScoreCalcMode: null,
        YearPlanConfigs: [],
        Rate: 0,
        copyItem:null,
        PlanComplateTime: null
      },
      resloveFormRules: {
        WorkItem: [
          { required: true, message: '请填写工作项目', trigger: 'blur' }
        ],
        WorkDescription: [
          { required: true, message: '请输入工作描述', trigger: 'blur' }
        ],
        ExamBasis: [
          { required: true, message: '请填写考核依据', trigger: 'blur' }
        ],
        ScoreCalcMode: [
          { required: true, message: '请填写核算方式', trigger: 'blur' }
        ],
        PlanComplateTime: [
          { required: true, message: '请选择计划完成时间', trigger: 'blur' }
        ]
      },
      addresloveForm: {
        FieldName: null,
        FieldType: null,
        FieldLength: null
      },
      addresloveFormRules: {
        FieldName: [
          { required: true, message: '请填写字段名称', trigger: 'blur' }
        ],
        FieldType: [
          { required: true, message: '请选择字段类型', trigger: 'blur' }
        ],
        FieldUnit: [
          { required: true, message: '请填写字段单位', trigger: 'blur' }
        ],
        FieldLength: [
          { required: true, message: '请输入可填写长度', trigger: 'blur' }
        ]
      },
      fieldTypeOptions: [
        {
          value: 1,
          label: "文本"
        },
        {
          value: 2,
          label: "数字"
        }
        // },
        // {
        // value: 3,
        // label: "时间"
        // },
        // {
        // value: 4,
        // label: "日期"
        // },
      ],
      copyForList:[
        
      ],
      yearoptions:[{
          value: 2021,
          label: '2021年度'
        }, {
          value: 2022,
          label: '2022年度'
        }, {
          value: 2023,
          label: '2023年度'
        }, {
          value: 2024,
          label: '2024年度'
        }, {
          value: 2025,
          label: '2025年度'
        }, {
          value: 2026,
          label: '2026年度'
        }, {
          value: 2027,
          label: '2027年度'
        }
      ],
      yearvalue:new Date().getFullYear(),
      fenleioptions:[{
          value: '',
          label: '全部分类'
        }, {
          value: '北方大区',
          label: '北方大区'
        }, {
          value: '产业设计研究院',
          label: '产业设计研究院'
        }, {
          value: '复合材料板块',
          label: '复合材料板块'
        }, {
          value: '国贸',
          label: '国贸'
        }, {
          value: '环保板块',
          label: '环保板块'
        }, {
          value: '空调板块',
          label: '空调板块'
        }, {
          value: '酷菱',
          label: '酷菱'
        }, {
          value: '南方大区',
          label: '南方大区'
        }, {
          value: '四大中心',
          label: '四大中心'
        },{
          value: '通风、人防板块',
          label: '通风、人防板块'
        }, {
          value: '直属区域公司',
          label: '直属区域公司'
        }
      ],
      sortoptions:[{
          value: 1,
          label: '按创建时间排序'
        }, {
          value: 2,
          label: '按工作项目排序'
        }, {
          value: 3,
          label: '按责任人排序'
        }
      ],
      sortvalue:1,
      collectBtn:false,
      collectRowId:'',
      mapLevel:new Map()
    }
  },
  methods: {
    mycellStyle(row,column,rowIndex,columnIndex)
    {
      // return 'background:red'
      // console.log(row);
      if(row.row.Level===1)
      {
        return 'background:#fff'
      }
      else if(row.row.Level===2)
      {
        return 'background:#e9f3ff'
      }
      else if(row.row.Level===3)
      {
        return 'background:#d4e9ff';
      }
      else if(row.row.Level===4)
      {
        return 'background:#bfdeff';
      }
      else if(row.row.Level===5)
      {
        return 'background:#aad2ff';
      }
      else if(row.row.Level===6)
      {
        return 'background:#96c8ff';
      }
      else if(row.row.Level===7)
      {
        return 'background:#81bdff';
      }
      else if(row.row.Level===8)
      {
        return 'background:#6eb3ff';
      }
      else if(row.row.Level===9)
      {
        return 'background:#5ba8ff';
      }
      else if(row.row.Level===10)
      {
        return 'background:#499eff';
      }
      return ''
    },
    handleCollect(row)
    {
      if(row.CollectId==null)
      {
        var para={};
        para.TaskID=row.Id;
        var user = JSON.parse(window.localStorage.user);
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para)
        // return;
        AddTaskCollect(para).then((res) => {
          this.$message({
            message: '已特别关注',
            type: 'success'
          });
          // console.log(res);
          var id=res.data.response;
          row.CollectId=id;
        })
      }else{
        this.$confirm("确定要关闭对此任务的关注吗？", "提示", {}).then(() => {
          var para={};
          para.Id=row.CollectId;
          para.TaskID=row.Id;
          RemoveTaskCollect(para).then((res) => {
            this.$message({
              message: '已取消关注',
              type: 'success'
            });
            row.CollectId=null;
          })
        }).catch(()=>{});
      }
    },
    tabmouseEnter(row, column, cell, event)
    {
      this.collectBtn=true;
      this.collectRowId=row.Id;
      // console.log(row)
    },
    tabmouseLeave(row, column, cell, event)
    {
      this.collectBtn=false;
      this.collectRowId='';
    },
    handleChangeReview(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      return user.name;
    },
    handleReplyReview(progress,rid)
    {
      // console.log(progress);
      // console.log(rid);
      let self=this;
      var progressId = progress.Id;
      var user = JSON.parse(window.localStorage.user);
      var para={};
      para.TaskID=this.checkForm.Id;
      para.ProgressID=progressId;
      para.ReplayID = rid;
      para.ReviewContent = self.content;
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          for(var a=0;a<self.yearplans.length;a++)
          {
            if(this.yearplans[a].Id==progressId)
            {
              this.yearplans[a].ReviewCount=this.yearplans[a].ReviewCount+1;
              break;
            }
          }
          this.loadReview(progressId);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    handleSendReview(progress)
    {
      let self=this;
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
      {
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = null;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            // console.log(self.yearplans)
            for(var a=0;a<self.yearplans.length;a++)
            {
              if(this.yearplans[a].Id==progressId)
              {
                this.yearplans[a].ReviewCount=this.yearplans[a].ReviewCount+1;
                break;
              }
            }
            this.loadReview(progressId);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      }
      // )
    },
    loadReview(progressid)
    {
      let self=this;
      // console.log(progressid)
      let para = { progressId: progressid };
      QueryReviewByProgressId(para).then((res) => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data);
        self.mapReview.set(progressid,data);
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      });
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={
        CreatedBy:'',
        CreatedId:'',
        CreatedTime:'',
        ReviewContent:''
      };
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    dealFiles2 (data) {
      if (data.backData.length > 0) {
        this.addProgressForm.FileArry = data.backData;
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ',';
          fileaddress += element.URL;
          fileaddress += ',';
        });
        this.addProgressForm.FileName = filename;
        this.addProgressForm.FileAddress = fileaddress;
      }
    },
    deleteFiles2 (data) {
      if (data.backData.length >= 0) {
        this.addProgressForm.FileArry = "";
        var filename = "";
        var fileaddress = "";
        data.backData.forEach(element => {
          filename += element.FileName;
          filename += ",";
          fileaddress += element.URL;
          fileaddress += ",";
        });
        this.addProgressForm.FileName = filename;
        this.addProgressForm.FileAddress = fileaddress;
      }
    },
    handleFormatTime(t)
    {
      return t;
    },
    handleYearplanProcessSelectChange (taskid, index) {
      this.currentYearplanActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
    showYearplanTaskFile (file) {
      window.open(file, "_blank");
    },
    handleProcessSelectChange (taskid, index) {
      this.currentActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
    showTaskFile (file) {
      window.open(file, "_blank");
    },
    handleProcessInput (val) {
      if (this.pValue2 < this.pValue1) {
        this.pValue2 = this.pValue1;
      }
      if (this.progresscheck == true) {
        if (this.pValue2 >= 100) {
          if(this.WorkItemType=='日常')
          {
            var t = this.checkForm.PlanComplateTime;
            var m = new Date(t).getMonth();
            var m2 = new Date().getMonth();
            if(m2<m)
            {
              this.$message({
                message: '日常类年度计划任务不建议在计划完成时间前标记完成',
                type: 'info'
              });
            }
          }

          if (this.checkForm.IsNeedApproval == false) {
            this.$message({
              // message: '任务已完成，请提交！',
              message: '年度计划任务为长周期目标任务，请合理推进任务进度！',
              type: 'info'
            });
          } else {
            this.$message({
              message: '任务已完成，请提交审核！',
              type: 'warning'
            });
          }
          this.pValue2 = 100;
        }
      } else {
        if (this.pValue2 > 95) {
          this.$message({
            message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
            type: "warning",
            duration: 10000
          });
          this.pValue2 = 95;
        }
      }
    },
    handleCloseAboutTaskList () {
      this.lookAboutTaskVisible = false;
    },
    handleSearchAboutTask () {
      var taskclassify = this.taskClassify;
      // console.log(taskclassify);
      QueryYearPlanAboutTaskByYearplanId({ yearplantaskid: this.yearplantaskid, taskclassify: taskclassify, month: '' }).then(res => {
        // console.log(res);
        this.aboutTaskList = res.data.response.data;
      });
    },
    MultitableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
    handleLookMyPingfen (row) {
      var yearplantaskid = row.Id;
      this.pingguWorkitem = row.WorkItem;
      this.jixiaopingguVisible = true;
      QueryTaskPingfenByTaskId({ taskId: yearplantaskid, month: '' }).then(res => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data)
        // console.log(yearplantaskid);
        this.aboutTaskPingguList = data;
      });
    },
    handleLookAboutTask (row) {
      // console.log(row)
      this.yearplantaskid = row.Id;
      this.lookAboutTaskVisible = true;
      QueryYearPlanAboutTaskByYearplanId({ yearplantaskid: this.yearplantaskid, taskclassify: 0, month: '' }).then(res => {
        // console.log(res);
        this.aboutTaskList = res.data.response.data;
      });
    },
    callFunction (item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);

    },
    selectCurrentRow (val) {
      this.currentRow = val;
    },
    taskProgressRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
    },
    onRowClick (row, event, column) {
      //行点击消除new标记
      this.currentProgressRowIndex = row.index;
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    formatOfYear: function (row, colume) {
      return (!row.OfYear || row.OfYear == '') ? '' : util.formatDate.format(new Date(row.OfYear), 'yyyy');
    },
    formatStatus: function (row, column) {
      return row.Status == 0 ? '进行中' : row.Status == 1 ? '审批中' : row.Status == 2 ? '已完成' : row.Status == -1 ? '已取消' : row.Status == 3 ? '退回' : '未知';
    },
    formatSource: function (row, column) {
      return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
    },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    formatLastInputProcessTime: function(row,column) {
        if(row.LastInputProcess=="0001-01-01 00:00:00")
        {
            return '未维护'
        }else{
            return (!row.LastInputProcess || row.LastInputProcess == '') ? '' : util.formatDate.dateformat(new Date(row.LastInputProcess))+'维护';
        }
    },
    formatTipLastInputProcessTime: function (row, column) {
        if(row.LastInputProcess=="0001-01-01 00:00:00")
        {
            return '未维护'
        }else{
            return (!row.LastInputProcess || row.LastInputProcess == '') ? '' : util.formatDate.format(new Date(row.LastInputProcess),'yyyy-MM-dd hh:mm');
        }
    },
    formatCreatedOn: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    formatProgress: function (row, col) {
      return row.TaskProgressValue + "%";
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        return row.CommonTaskClassifyText;
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          return "其他";
        }
      }
    },
    FieldTypeFormat: function (row, colume) {
      if (row.FieldType == 1) {
        return "文本";
      } else if (row.FieldType == 2) {
        return "数字";
      } else if (row.FieldType == 3) {
        return "时间";
      } else {
        return "日期";
      }
    },
    FieldMustInputFormat: function (row, colume) {
      if (row.FieldMustInput == 1) {
        return "是";
      } else {
        return "否";
      }
    },
    selectchange (val) {
      var ls = this.CommonTaskClassifyList;
      for (var a = 0; a < ls.length; a++) {
        if (ls[a].Id == val) {
          if (ls[a].IsNeedUpLeaderApproval == 1) {
            this.$message({
              message: '该任务类型下的任务需上级领导' + this.upleader + '审核成功后方可执行！',
              type: 'warning',
              duration: 10000
            });
            this.assigncontrol = false;
          } else {
            this.assigncontrol = true;
          }
        }
      }
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getYearPlans();
      // this.page = 1;
    },
    selectProRow (val) {
      this.proRow = val;
    },
    load (tree, treeNode, resolve) {
      const pid = tree.Id;
      this.maps.set(pid, { tree, treeNode, resolve });
      let para = {
        sort: this.sortvalue,
        taskId: tree.Id,
      };
      QueryYearPlanChildTasksByParentId(para).then((res) => {
        var myarr = new Array();
        var number = 0;
        res.data.response.forEach(element => {
          // console.log(element);
          // console.log(element.IsHasNormalTasks+","+element.IsHasChildrenYearplan);
          number++;
          element.Number = number;
          if(element.IsHasNormalTasks || element.IsHasChildrenYearplan)
          {
            element.IsHasChildren = true;
          }else{
            element.IsHasChildren = false;
          }
          // console.log(element.Tag);
          var has = this.mapLevel.has(element.Tag)
          var parentLevel = 0;
          if(has)
          {
            parentLevel = this.mapLevel.get(element.Tag);
          }
          else
          {
            parentLevel = this.mapLevel.get(element.ParentId);
          }
          var level = parentLevel+1;
          if(level==2)
          {
            element.MarginLeft = 30;
          }else if(level == 3){
            element.MarginLeft = 60;
          }else if(level == 4){
            element.MarginLeft = 90;
          }else if(level == 5){
            element.MarginLeft = 120;
          }else if(level == 6){
            element.MarginLeft = 150;
          }else if(level == 7){
            element.MarginLeft = 180;
          }else if(level == 8){
            element.MarginLeft = 210;
          }else if(level == 9){
            element.MarginLeft = 240;
          }else if(level == 10){
            element.MarginLeft = 270;
          }
          element.Level = level;
          this.mapLevel.set(element.Id,level);
          myarr.push(element)
        });
        resolve(myarr);
        // resolve(res.data.response)
      });
      // console.log(this.maps);
    },
    upchildrenDom (parentId) {
      // console.log(parentId);
      // console.log(this.maps);
      if (this.maps.length == 0) {

      }
      const { tree, treeNode, resolve } = this.maps.get(parentId); //根据pid取出对应的节点数据
      this.$set(this.$refs.tabYearplan.store.states.lazyTreeNodeMap, parentId, []); //将对应节点下的数据清空，从而实现数据的重新加载
      this.load(tree, treeNode, resolve)
    },
    //获取上级级领导
    getLeader () {
      // var user = JSON.parse(window.localStorage.user);
      // let para = { userCode: user.sub };
      // GetMyUpLeader(para).then((res) => {
      //   this.upleader = res.data.response;
      // });
    },
    getYearPlans () {
      // var user = JSON.parse(window.localStorage.user);
      let para = {
        sort: this.sortvalue,
        year: this.yearvalue,
        addmode: 1,
        people: this.zerenren,
        tips: this.fenlei,
        pageIndex: this.page,
        pageSize: this.pageSize,
        queryKey: this.filters.name
      };
      var wnagzhijunUserCode = 'f294658d-ed78-461d-bb7d-a36f012b9365';//王志军总裁
      if(this.zerenren.trim()=='' && this.fenlei.trim()=='')
      {
        para.userCode = wnagzhijunUserCode
      }else{
        para.userCode = '';
      }
      this.mapLevel.clear();
      QueryPageYearPlanByUserCode(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        var arr=new Array();
        var number = (this.page-1)*this.pageSize;
        res.data.response.data.forEach(element => {
          // console.log(element);
            number++;
            element.Number=number;
            if(element.IsHasChildrenYearplan || element.IsHasNormalTasks)
            {
              element.IsHasChildren=true;
            }else{
              element.IsHasChildren=false;
            }
            element.MarginLeft=3;
            element.Level = 1;
            this.mapLevel.set(element.Id,1);
            arr.push(element);
            // if(para.userCode =='' || (element.ParentId==null && element.PersonOfDutyCode == wnagzhijunUserCode)){
            //   arr.push(element);
            // }
        });
        this.users = arr;
      });
    },
    //下一步
    nextStep () {
      if (this.addForm.WorkItem != null && this.addForm.WorkDescription != null && this.addForm.PlanComplateTime != null) {
        this.addAssignFormVisible = true;
        this.addForm.WorkItem2 = this.addForm.WorkItem;
        this.addForm.WorkDescription2 = this.addForm.WorkDescription;
      } else {
        this.tipMessage();
      }

    },
    //编辑
    handleEdit(){
          let row = this.currentRow;
          var ucode = JSON.parse(window.localStorage.user);
            if (!row) {
            this.$message({
            message: "请选择要编辑的一行数据！",
            type: "error",
            duration: 10000
          });
            return;
        }else if(row.Status == 4){
            this.$message({
                message: "该年度计划仍在审核中,不能编辑！",
                type: "error",
                duration: 10000
            });
            return;
    //    }else if(ucode.uRealName != row.PersonOfDuty){
    //         this.$message({
    //             message: "非该年度计划责任人,不能编辑！",
    //             type: "error",
    //             duration: 10000
    //         });
    //         return;
        }
    //    else if(row.TaskProgressValue != 0){
    //         this.$message({
    //             message: '当前任务已开始进行，不能编辑',
    //             type: 'error',
    //             duration: 10000
    //         });
    //    }
        else{
            this.editFormVisible = true;
            this.editForm = Object.assign({},row);
        }
    },
    //编辑提交
    editSubmit(){
        this.$refs.editForm.validate((valid) => {
            if(valid){
                let row = this.currentRow;
                let para = Object.assign({},this.editForm);
                para.Id = this.editForm.YearPlanId;
                para.TaskId = this.editForm.Id;
                if(this.editForm.WorkItem == null || this.editForm.WorkDescription == null || this.editForm.ExamBasis == null || this.editForm.ScoreCalcMode == null || this.editForm.Rate == null || this.editForm.PlanComplateTime == null || this.editForm.OfYear == null){
                    this.tipMessage();
                    return;
                }
                this.Loadingstart();
                UpdateYearPlanTaskById(para).then((res) => {
                    if(res.data.success) {
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                        this.Loadignend();
                        this.$refs['editForm'].resetFields();
                        // this.currentRow = null;
                        this.editFormVisible = false;
                        // this.getYearPlans();
                        // let parentId= row.ParentId;
                        // this.upchildrenDom(parentId);

                        this.users = [];
                        setTimeout(() => {
                          this.getYearPlans();
                        }, 1000);
                        // if(this.maps.length==0)
                        // {
                        //     this.getYearPlans();
                        // }else{
                        //     let parentId= para.ParentId;
                        //     this.upchildrenDom(parentId);
                        // }
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                });
            }
        });
    },
    //新增
    handleAdd () {
      this.showNext = false;
      this.showSubmit = true;
      this.needName = null;
      this.addFormVisible = true;
      var user = JSON.parse(window.localStorage.user);
      this.dutyneedname = user.name;
      this.addForm.PersonOfDuty = user.name;
      this.addForm.PersonOfDutyCode = user.sub;
      // this.$message({
      //     message: '该年度计划需上级领导审核通过后方可执行!',
      //     type: 'warning'
      // });
      GetClassifyList().then((res) => {
        this.CommonTaskClassifyList = res.data.response;
      });
    },
    handleChangeCopyItem(val)
    {
      // console.log(val);
      this.resloveForm.WorkItem = val.WorkItem;
      this.resloveForm.WorkDescription = val.WorkDescription;
      this.resloveForm.ExamBasis = val.ExamBasis;
      this.resloveForm.ScoreCalcMode = val.ScoreCalcMode;
      this.resloveForm.Rate = val.Rate;
      this.resloveForm.PlanComplateTime = val.PlanComplateTime;
      this.resloveLeave = val.WorkType.toString();
    },
    addtable () {
      this.addresloveVisible = true;
      this.$refs.addresloveForm.resetFields();
    },
    addresloveFormSubmit () {
      if (this.addresloveForm.FieldName == null || this.addresloveForm.FieldType == null || this.addresloveForm.FieldUnit == null || this.addresloveForm.FieldLength == null) {
        this.$message({
          message: '请填写完整表单!',
          type: 'warning',
          duration: 10000
        });
        return;
      }
      var ucode = JSON.parse(window.localStorage.user);
      let para = Object.assign({}, this.addresloveForm);
      para.CreatedBy = ucode.name;
      para.CreatedId = ucode.sub;
      para.FieldMustInput = this.NeedInput;
      this.resloveForm.YearPlanConfigs.push(para);
      this.addresloveVisible = false;
    },
    deletetable (row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning"
      }).then(_ => {
        this.resloveForm.YearPlanConfigs.splice(
          this.resloveForm.YearPlanConfigs.indexOf(row),
          1
        );
      });
    },
    resloveSubmit () {
      if (this.resloveForm.DecomposesUserName == null || this.resloveForm.WorkItem == null || this.resloveForm.WorkDescription == null || this.resloveForm.ExamBasis == null || this.resloveForm.ScoreCalcMode == null || this.resloveForm.PlanComplateTime == null) {
        this.$message({
          message: '请填写完整表单!',
          type: 'warning',
          duration: 10000
        });
        return;
      } else if (this.resloveForm.YearPlanConfigs.length == 0) {
        // this.$message({
        //     message: '多行数据为必填，请填写多行数据!',
        //     type: 'warning',
        //     duration: 10000
        // });
      }
      let para = Object.assign({}, this.resloveForm);
      para.WorkType = this.resloveLeave;
      let mycurrentRow = this.currentRow;
      para.TaskId = this.currentRow.Id;
      para.YearPlanId = this.currentRow.YearPlanId;
      this.$confirm('确认分解此年度计划吗？', '提示', {}).then(() => {
        this.Loadingstart();
        DecomposesYearPlan(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.Loadignend();
            this.$refs['resloveForm'].resetFields();
            // this.currentRow = null;
            this.resloveVisible = false;
            // this.getYearPlans();
            if (this.maps.length == 0) {
              this.getYearPlans();
            } else {
              let parentId = para.TaskId;
              this.upchildrenDom(parentId);
            }
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      });

    },
    //删除
    handleDelete () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      } else if (row.ParentId != null) {
        this.$message({
          message: '当前任务为子任务，不能删除',
          type: 'error',
          duration: 10000
        });
      } else {
        this.$confirm('确认删除年度计划任务吗？', '提示', {}).then(() => {
          let para = { taskId: row.Id };
          DeleteTaskById(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.currentRow = null;
              this.getYearPlans();
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
            }
          });
        });
      }
    },
    //双击查看详细信息
    checkInfo (row) {
      var ucode = JSON.parse(window.localStorage.user);
      this.checkVisible = true;
      if (row.WorkType == 1) {
        this.WorkItemType = "日常"
      } else {
        this.WorkItemType = "项目"
      }
      this.pValue1 = row.TaskProgressValue;
      this.pValue2 = row.TaskProgressValue;
      this.progresscheck = row.ChildrenProgressComplated;
      if (row.PersonOfDutyCode != ucode.sub || row.Status != 0) {
        this.isyearplanpro = false;
      } else {
        if (this.pValue1 < 100) {
          if (this.pValue1 == 95 && this.progresscheck == false) {
            this.isyearplanpro = false;
          } else {
            this.isyearplanpro = true;
          }
        } else {
          this.isyearplanpro = false;
        }
      }
      GetClassifyList().then((res) => {
        this.CommonTaskClassifyList = res.data.response;
      });
      this.checkForm = Object.assign({}, row);
      let para = { taskId: row.Id };
      QueryTaskProgressByTaskId(para).then((res) => {
        // this.yearplans = res.data.response;
        var data = res.data.response;
        var formatData = new Array();
        for (var a = 0; a < data.length; a++) {
          var element = data[a];
          element.Files = [];
          if (element.FileName != null) {
            var arr = element.FileName.split(",");
            var arr2 = element.FileAddress.split(",");
            var files = [];
            for (var d = 0; d < arr.length; d++) {
              var f = {};
              f.FileName = arr[d];
              f.FileAddress = arr2[d];
              files.push(f);
            }
            element.Files = files;
          }
          formatData.push(element);
        }
        this.yearplans = formatData;
        // console.log(this.yearplans)
        this.$nextTick(function () {
          var div = document.getElementsByClassName("yeardiv");
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight;
          }
        })
      });
    },
    checkAboutInfo (row) {
      var ucode = JSON.parse(window.localStorage.user);
      this.lookOneAboutTaskVisible = true;
      if (row.WorkType == 1) {
        this.WorkItemType = "日常"
      } else {
        this.WorkItemType = "项目"
      }
      this.CommonTaskClassifyText = row.CommonTaskClassifyText;
      // console.log('row');
      // console.log(row);
      // console.log(this.CommonTaskClassifyText);
      this.pValue1 = row.TaskProgressValue;
      this.pValue2 = row.TaskProgressValue;
      this.progresscheck = row.ChildrenProgressComplated;
      if (row.PersonOfDuty != ucode.name || row.Status != 0) {
        this.isyearplanpro = false;
      } else {
        if (this.pValue1 < 100) {
          if (this.pValue1 == 95 && this.progresscheck == false) {
            this.isyearplanpro = false;
          } else {
            this.isyearplanpro = true;
          }
        } else {
          this.isyearplanpro = false;
        }
      }
      GetClassifyList().then((res) => {
        this.CommonTaskClassifyList = res.data.response;
      });
      this.checkForm = Object.assign({}, row);
      let para = { taskId: row.Id };
      QueryTaskProgressByTaskId(para).then((res) => {
        // this.tasks = res.data.response;
        var data = res.data.response;
        let formatData = new Array();
        for (var a = 0; a < data.length; a++) {
          var element = data[a];
          element.Files = [];
          if (element.FileName != null) {
            var arr = element.FileName.split(",");
            var arr2 = element.FileAddress.split(",");
            var files = [];
            for (var d = 0; d < arr.length; d++) {
              var f = {};
              f.FileName = arr[d];
              f.FileAddress = arr2[d];
              files.push(f);
            }
            element.Files = files;
          }
          formatData.push(element);
        }
        this.tasks = formatData;
        this.$nextTick(function () {
          var div = document.getElementsByClassName("tasksdiv");
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight;
          }
        })
      });
    },
    checkclose () {
      this.checkVisible = false;
      this.pValue1 = 0;
      //this.getYearPlans();
    },
    //进度新增
    addpro () {
      this.addProgressVisible = true;
      this.addProgressForm.ProgressDescription = null;
      this.showminus = false;
      this.showplus = true;
    },
    addyearplanopen () {
      if (this.progresscheck == false) {
        this.$message({
          message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
          type: "warning",
          duration: 10000
        });
      }
    },
    //进度新增提交
    checkSubmit () {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        taskID: this.checkForm.Id,
        progressDescription: this.addProgressForm.ProgressDescription,
        progressValue: this.pValue2,
        filename: this.addProgressForm.FileName,
        fileaddress: this.addProgressForm.FileAddress,
        createdId: user.sub,
        createdBy: user.name
      };
      if (this.addProgressForm.ProgressDescription == null) {
        this.$message({
          message: '请填写进度内容',
          type: 'warning'
        });
      } else {

        if(para.progressValue>=50)
        {

        }
        this.$confirm("年度计划任务为长周期目标任务，请合理推进任务进度，确定要继续维护进度吗?", "提示", {
        })
        .then(() => {
          this.Loadingstart();
          AddTaskProgress(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.Loadignend();
              this.addProgressVisible = false;
              let para = { taskId: this.checkForm.Id };
              QueryTaskProgressByTaskId(para).then((res) => {
                // this.yearplans = res.data.response;
                var data = res.data.response;
                var formatData = new Array();
                for (var a = 0; a < data.length; a++) {
                  var element = data[a];
                  element.Files = [];
                  if (element.FileName != null) {
                    var arr = element.FileName.split(",");
                    var arr2 = element.FileAddress.split(",");
                    var files = [];
                    for (var d = 0; d < arr.length; d++) {
                      var f = {};
                      f.FileName = arr[d];
                      f.FileAddress = arr2[d];
                      files.push(f);
                    }
                    element.Files = files;
                  }
                  formatData.push(element);
                }
                this.yearplans = formatData;
                this.RefreshProgress(this.yearplans);
                this.$nextTick(function () {
                  var div = document.getElementsByClassName("yeardiv");
                  if (div && 0 < div.length) {
                    div[0].scrollTop = div[0].scrollHeight;
                  }
                })
              });
              this.getYearPlans();
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
            }
          });
        })
        .catch(() => {});
      }
    },
    //进度编辑
    editpro () {
      let row = this.proRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error"
        });
        return;
      }
      this.editProgressVisible = true;
      this.editProgressForm = Object.assign({}, row);
    },
    //删除选中进度
    deletepro () {
      // let row = this.proRow;
      if (!this.currentYearplanActiveId) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
          duration: 10000
        });
        return;
      }
      var length = this.yearplans.length;
      if (this.currentProgressRowIndex < length - 1) {
        this.$message({
          message: "只允许删除最后一条进度",
          type: "error",
          duration: 10000
        });
        return;
      }
      this.$confirm('确认删除进度吗？', '提示', {}).then(() => {
        DeleteTaskProgress({ id: this.currentYearplanActiveId }).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.currentYearplanActiveId = null;
            let para = { taskId: this.checkForm.Id };
            QueryTaskProgressByTaskId(para).then((res) => {
              // this.yearplans = res.data.response;
              var data = res.data.response;
              var formatData = new Array();
              for (var a = 0; a < data.length; a++) {
                var element = data[a];
                element.Files = [];
                if (element.FileName != null) {
                  var arr = element.FileName.split(",");
                  var arr2 = element.FileAddress.split(",");
                  var files = [];
                  for (var d = 0; d < arr.length; d++) {
                    var f = {};
                    f.FileName = arr[d];
                    f.FileAddress = arr2[d];
                    files.push(f);
                  }
                  element.Files = files;
                }
                formatData.push(element);
              }
              this.yearplans = formatData;

              this.RefreshProgress(this.yearplans);
              this.$nextTick(function () {
                var div = document.getElementsByClassName("yeardiv");
                if (div && 0 < div.length) {
                  div[0].scrollTop = div[0].scrollHeight;
                }
              })
            });
            this.getYearPlans();
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      });
    },
    //责任人回调
    dutycallFunction (newdata) {
      this.dutyneedname = null;
      this.addForm.PersonOfDutyCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.PersonOfDuty = res.data.data[0].name;
          this.dutyneedname = this.addForm.PersonOfDuty;
        }
      });
    },
    //指派人回调
    assigncallFunction (newdata) {
      this.needName = null;
      this.addForm.AssignCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.AssignName = res.data.data[0].name;
          this.needName = this.addForm.AssignName;
        }
      });
    },
    //分解人回调
    reslovecallFunction (newdata) {
      this.needresloveName = null;
      this.resloveForm.DecomposesUserCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.resloveForm.DecomposesUserName = res.data.data[0].name;
          this.needresloveName = this.resloveForm.DecomposesUserName;
        }
      });
    },
    clickAssign () {
      if (this.addForm.IsAssign == true) {
        this.showNext = true;
        this.showSubmit = false;
      } else {
        this.showSubmit = true;
        this.showNext = false;
      }
    },
    clickclose () {
      this.addForm = {                   //新增表单初始化
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsAssign: false,
        CreatedBy: null,
        CreatedId: null,
        AssignName: null,
        AssignCode: null,
        PersonOfDuty: null,
        PersonOfDutyCode: null
      }
      this.$refs.addForm.resetFields();
    },
    increase () {
      this.pValue2 += 5;
      if (this.pValue2 > this.pValue1) {
        this.showminus = true;
      }
      if (this.progresscheck == true) {
        if (this.pValue2 > 100) {
          if (this.checkForm.IsNeedApproval == false) {
            this.$message({
              // message: '任务已完成，请提交！',
              message: '年度计划任务为长周期目标任务，请合理推进任务进度',
              type: 'success'
            });
          } else {
            this.$message({
              message: '任务已完成，请提交审核！',
              type: 'warning'
            });
          }

          this.pValue2 = 100;
        }
      } else {
        if (this.pValue2 == 95) {
          this.$message({
            message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
            type: "warning",
            duration: 10000
          });
          this.showplus = false;
          this.this.pValue2 = 95;
        }
      }
    },
    decrease () {
      this.pValue2 -= 5;
      if (this.pValue2 <= this.pValue1) {
        this.showminus = false;
      }
      if (this.progresscheck == false) {
        if (this.pValue2 < 95) {
          this.showplus = true;
        } else {
          this.showplus = true;
        }
      }
    },
    tipMessage () {
      this.$message({
        message: '填写未完成，请继续填写',
        type: 'warning'
      });
    },
    RefreshProgress (tasksProgressRow) {
      var rowlength = tasksProgressRow.length;
      if (rowlength > 0) {
        this.pValue2 = tasksProgressRow[tasksProgressRow.length - 1].ProgressValue;
      } else {
        this.pValue2 = 0;
      }
      this.pValue1 = this.pValue2;
      if (this.pValue1 == 95 && this.progresscheck == false) {
        this.istaskpro = false;
      } else {
        this.istaskpro = true;
      }
    },
    addopen () {
      this.$message({
        message: '该年度计划需上级领导审核通过后方可执行!',
        type: 'warning'
      });
    },
    handleYearPlan () {
      this.excelVisible = true;
    },
    handleRemove (file, fileList) {
      this.fileListUpload = [];
      this.yearplanexcelList = [];
    },
    //表格选择
    handleSelectionChange (val) {
      this.submitList = val;
      //  for(var i=0; i<this.submitList.length; i++){
      //         this.submitList[i].OfYear = this.submitList[i].OfYear + "-01-01";
      //  }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
    Loadingstart () {
      this.loading = Loading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0,0,0,0.1)'
      })
    },
    Loadignend () {
      this.loading.close();
    }
  },
  mounted () {

    var user = JSON.parse(window.localStorage.user);
    var username = user.name;
    if(username == '扈淑君')
    {
      this.fenleioptions.forEach(element => {
        if(element.value!='国贸')
        {
          element.disabled = true;
        }else{
          this.fenlei = "国贸";
        }
      });
    }else if(username == '杨中兴')
    {
      this.fenleioptions.forEach(element => {
        if(element.value!='北方大区' && element.value!='南方大区' && element.value!='直属区域公司')
        {
          element.disabled = true;
        }
      });
      this.fenlei = "北方大区";
    }else if(username == '苏厚泉')
    {
      this.fenleioptions.forEach(element => {
        if(element.value!='环保板块' && element.value!='通风、人防板块' && element.value!='空调板块')
        {
          element.disabled = true;
        }
      });
      this.fenlei = "环保板块";
    }else if(username == '管志平')
    {
      this.fenleioptions.forEach(element => {
        if(element.value!='北方大区')
        {
          element.disabled = true;
        }
      });
      this.fenlei = "北方大区";
    }else if(username == '曹大军')
    {
      this.fenleioptions.forEach(element => {
        if(element.value!='南方大区')
        {
          element.disabled = true;
        }
      });
      this.fenlei = "南方大区";
    }

    
    this.getYearPlans();
    this.getLeader();
    var user = JSON.parse(window.localStorage.user);
    this.localuser = user.name;

    let routers = window.localStorage.router ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
    
  }
}
</script>
<style scoped>

.yearplandiv,
.yearplancard,
.managercheckdiv,
.managercheckcard {
  height: calc(100vh - 120px);
}
.yeardiv,
.tasksdiv {
  max-height: calc(100vh - 230px);
  overflow: auto;
  padding-left: 2px;
}
.active {
  background-color: #00000014;
}
.eldropdownitem,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

</style>